
import axios from 'axios'
import dayjs from 'dayjs'
import { RECORD_OBJ } from '@/config/constEnum'
import { defineComponent, ref, onMounted } from 'vue'
export default defineComponent({
  name: 'Analysis',
  setup() {
    const tableData = ref([])
    const total = ref(0)
    const pageSize = 10
    const page = ref(1)
    const canUserMsg = ref(0)
    const summary = ref({})
    const selectDate = ref(['', ''])
    const startDate = ref('')
    const endDate = ref('')

    const loading = ref(false)
    const onChangePage = (val: number) => {
      page.value = val
      getList()
    }

    const formatTime = (val: string | number | Date | dayjs.Dayjs | null | undefined) => {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    }

    const nowDate = dayjs().format('YYYY-MM-DD')

    const getList = () => {
      const reqData = {
        status: 'all',
        phone: 'all',
        size: pageSize,
        page: page.value
      }
      loading.value = true
      axios
        .get('/-/user/stat/history', { params: reqData })
        .then(res => {
          const data = res.data.msg
          total.value = data.total
          tableData.value = data.smss
        })
        .finally(() => {
          loading.value = false
        })
    }

    const getSummary = () => {
      loading.value = true
      const reqData = {
        start_date: startDate.value,
        end_date: endDate.value
      }
      axios
        .get('/-/user/stat/range', { params: reqData })
        .then(res => {
          summary.value = res.data.msg
        })
        .finally(() => {
          loading.value = false
        })
    }

    const getBalance = () => {
      axios.get('/-/user/finance/balance').then(res => {
        const data = res.data.msg
        canUserMsg.value = data
      })
    }

    const changeTime = (date: [string, string]) => {
      if (date.length > 0) {
        startDate.value = date[0]
        endDate.value = date[1]
        getSummary()
      }
    }

    onMounted(() => {
      getList()
      endDate.value = nowDate
      startDate.value = nowDate
      selectDate.value = [nowDate, nowDate]
      getBalance()
      getSummary()
    })
    return {
      total,
      page,
      loading,
      summary,
      nowDate,
      endDate,
      pageSize,
      startDate,
      tableData,
      canUserMsg,
      formatTime,
      changeTime,
      selectDate,
      onChangePage,
      recordObj: RECORD_OBJ
    }
  }
})
