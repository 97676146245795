<template>
  <div class="analysis-wrap" v-loading="loading" element-loading-text="加载中...">
    <el-row :gutter="12" class="analysis-send-content">
      <el-col :span="8">
        <el-card shadow="hover">
          <template #header>
            <div class="card-header">
              <span>可用短信条数</span>
            </div>
          </template>
          <p class="card-content card-height">总{{ canUserMsg }}</p>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card shadow="hover">
          <template #header>
            <div class="card-header">
              <span>{{ selectDate[0] }}日 至 {{ selectDate[1] }}日发送条数</span>
            </div>
          </template>
          <div class="content">
            <el-date-picker
              v-model="selectDate"
              type="daterange"
              range-separator="-"
              @change="changeTime"
              value-format="YYYY-MM-DD"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            />
            <p class="card-content">
              成功 {{ summary.sent_succeed }} / 失败
              {{ summary.sent_failed }}
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="ID" label="发送ID" width="120"></el-table-column>
      <el-table-column prop="Phone" label="发送手机号" width="200"></el-table-column>
      <el-table-column label="发送时间" width="300">
        <template #default="scope">
          {{ formatTime(scope.row.CreatedAt) }}
        </template>
      </el-table-column>
      <el-table-column label="发送状态" width="200">
        <template #default="scope">
          {{ recordObj[scope.row.Status] }}{{ scope.row.Status === 'failed' ? `(${scope.row.ChannelStatus})` : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="Content" label="内容"></el-table-column>
    </el-table>
    <el-pagination
      class="page"
      @current-change="onChangePage"
      v-model:current-page="page"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script lang="ts">
import axios from 'axios'
import dayjs from 'dayjs'
import { RECORD_OBJ } from '@/config/constEnum'
import { defineComponent, ref, onMounted } from 'vue'
export default defineComponent({
  name: 'Analysis',
  setup() {
    const tableData = ref([])
    const total = ref(0)
    const pageSize = 10
    const page = ref(1)
    const canUserMsg = ref(0)
    const summary = ref({})
    const selectDate = ref(['', ''])
    const startDate = ref('')
    const endDate = ref('')

    const loading = ref(false)
    const onChangePage = (val: number) => {
      page.value = val
      getList()
    }

    const formatTime = (val: string | number | Date | dayjs.Dayjs | null | undefined) => {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    }

    const nowDate = dayjs().format('YYYY-MM-DD')

    const getList = () => {
      const reqData = {
        status: 'all',
        phone: 'all',
        size: pageSize,
        page: page.value
      }
      loading.value = true
      axios
        .get('/-/user/stat/history', { params: reqData })
        .then(res => {
          const data = res.data.msg
          total.value = data.total
          tableData.value = data.smss
        })
        .finally(() => {
          loading.value = false
        })
    }

    const getSummary = () => {
      loading.value = true
      const reqData = {
        start_date: startDate.value,
        end_date: endDate.value
      }
      axios
        .get('/-/user/stat/range', { params: reqData })
        .then(res => {
          summary.value = res.data.msg
        })
        .finally(() => {
          loading.value = false
        })
    }

    const getBalance = () => {
      axios.get('/-/user/finance/balance').then(res => {
        const data = res.data.msg
        canUserMsg.value = data
      })
    }

    const changeTime = (date: [string, string]) => {
      if (date.length > 0) {
        startDate.value = date[0]
        endDate.value = date[1]
        getSummary()
      }
    }

    onMounted(() => {
      getList()
      endDate.value = nowDate
      startDate.value = nowDate
      selectDate.value = [nowDate, nowDate]
      getBalance()
      getSummary()
    })
    return {
      total,
      page,
      loading,
      summary,
      nowDate,
      endDate,
      pageSize,
      startDate,
      tableData,
      canUserMsg,
      formatTime,
      changeTime,
      selectDate,
      onChangePage,
      recordObj: RECORD_OBJ
    }
  }
})
</script>
<style lang="less">
.analysis-wrap {
  .analysis-send-content {
    padding-bottom: 20px;
    .card-header {
      font-size: 18px;
      font-weight: 600;
    }

    .content {
      .card-content {
        padding-left: 60px;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .card-height {
      height: 40px;
    }
  }

  .page {
    padding-top: 10px;
    text-align: right;
  }
}
</style>
